import { render, staticRenderFns } from "./PatchNotes.vue?vue&type=template&id=6d78f2c4"
import script from "./PatchNotes.vue?vue&type=script&lang=js"
export * from "./PatchNotes.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/patchNotes.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./PatchNotes.vue?vue&type=style&index=1&id=6d78f2c4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CAccordionIcon: require('@chakra-ui/vue').CAccordionIcon, CAccordionHeader: require('@chakra-ui/vue').CAccordionHeader, CAccordionPanel: require('@chakra-ui/vue').CAccordionPanel, CAccordionItem: require('@chakra-ui/vue').CAccordionItem, CAccordion: require('@chakra-ui/vue').CAccordion})
