const patchNotes2008 = [
  {
    text: `Changes to the “model” parameter which now takes “RAF Type Codes” corresponding to the [bold]“Monthly Membership Detailed Reports (MMR)”[/bold]. *`,
    id: 1,
  },
  {
    text:
      'The RAF score outputs will be [bold]normalized[/bold], i.e. calculated based on the Normalization Factor and Coding Intensity for [bold]Medicare Advantage[/bold] (Part C) Plans.',
    id: 2,
  },
  {
    text:
      'Change in the sequence of parameters for the [italic]formula based[/italic] approach to ensure consistency across different excel functions.',
    id: 3,
  },
  {
    text: 'Bug fixes involving Interaction Codes and their RAF scores.',
    id: 4,
  },
  {
    text:
      'Bug fixes around Member’s Age/Sex dependent HCCs, majorly in the [bold]“Affordable Care Act” (ACA)[/bold] Plans.',
    id: 5,
  },
]

const patchNotes2024 = [
  {
    text:
      'Incorporated ESRD models in Medicare Part C for DOS Years 2020-2023.',
    id: 1,
  },
]
const patchNotes_11_03_2024 = [
  {
    text:
      'Added a toggle for the users to enable them to choose between Normalised and raw RAF Scores.',
    id: 1,
  },
]
const patchNotes_05_09_2024 = [
  {
    text: `Incorporated v28 and v24 models in Medicare Part C for DOS Year 2024.`,
    id: 1,
  },
  {
    text: 'Incorporated v24 model in Medicare Part C for DOS Year 2023.',
    id: 2,
  },
  {
    text: 'Incorporated hhs_v07 Commercial model for DOS Year 2024.',
    id: 3,
  },
]

const patchNotes = [
  {
    patchNote: patchNotes_05_09_2024,
    heading: 'Risk Adjustment Toolkit | v4.0.0.0',
    date: 'September 5, 2024',
  },
  {
    patchNote: patchNotes_11_03_2024,
    heading: 'Risk Adjustment Toolkit | v3.1.1.0',
    date: 'March 11, 2024',
  },
  {
    patchNote: patchNotes2024,
    heading: 'Risk Adjustment Toolkit | v3.0.0.0',
    date: 'February 5, 2024',
  },
  {
    patchNote: patchNotes2008,
    heading: 'Risk Adjustment Toolkit | v2.0.0.8',
    date: 'January 17, 2024',
  },
]

export default patchNotes
